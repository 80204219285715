<template>
  <v-data-table
    :headers="headers"
    :items="todos"
    :items-per-page="5"
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>待办事项</v-toolbar-title>
        <v-divider inset vertical class="mx-4"></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle dense>
              <v-btn v-bind="attrs" v-on="on">
                <v-icon color="green">mdi-file-outline</v-icon>
                新建
              </v-btn>
            </v-btn-toggle>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editedItem.flag"
                      :items="flags"
                      label="标志"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.valid_date"
                          label="截止日期"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.valid_date"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.content"
                      label="待办事项"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="informUid"
                      :items="users"
                      item-text="user_name"
                      item-value="user_id"
                      label="通知"
                      multiple
                      chips
                      hint="请选择需要通知的用户"
                      persistent-hint
                      @change="selectInformUser"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="blue darken-1" @click="close">
                取消
              </v-btn>
              <v-btn text color="blue darken-1" @click="save">
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">你确定要删除此备注吗？</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                取消
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                确定
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.index`]="{ item }">
      <span>{{ todos.indexOf(item) + 1 }}</span>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon class="mr-1" color="green" @click="changeItem(item)">
        mdi-alarm-snooze
      </v-icon>
      <v-icon class="mr-1" color="blue" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon color="orange" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { baseUrl } from "../env";
import authHeader from "../services/auth-header";

export default {
  data: () => ({
    headers: [
      {
        text: "序号",
        value: "index",
        align: "end",
        sortable: false,
        width: "80px"
      },
      { text: "标志", value: "flag", sortable: false, width: "80px" },
      { text: "待办事项", value: "content", sortable: false },
      { text: "截止日期", value: "valid_date", width: "150px" },
      { text: "状态", value: "status", width: "80px" },
      {
        text: "操作",
        value: "actions",
        sortable: false,
        width: "120px",
        align: "center"
      }
    ],
    todos: [],
    flags: ["普通", "重要", "紧急"],
    editedIndex: -1,
    editedItem: {
      id: 0,
      type: "TODO",
      ref_id: 0,
      content: "",
      flag: "",
      valid_date: "",
      status: "待办",
      inform_uid: null
    },
    defaultItem: {
      id: 0,
      type: "TODO",
      ref_id: 0,
      content: "",
      flag: "",
      valid_date: "",
      status: "待办",
      inform_uid: null
    },
    loading: false,
    menu: false,
    dialog: false,
    dialogDelete: false,
    informUid: null,
    users: []
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "新建" : "编辑";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.getUsers();
    this.getDataFromApi();
  },

  methods: {
    selectInformUser() {
      this.editedItem.inform_uid = this.informUid.toString();
    },

    getUsers() {
      fetch(baseUrl + "/api/users/EXCLUDE", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          if (Response.ok) {
            return Response.json();
          } else {
            return Promise.reject({
              status: Response.status,
              statusText: Response.statusText
            });
          }
        })
        .then(jsonData => {
          this.users = jsonData;
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        });
    },

    getDataFromApi() {
      this.loading = true;
      fetch(baseUrl + "/api/remarks/TODO", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          if (Response.ok) {
            return Response.json();
          } else {
            return Promise.reject({
              status: Response.status,
              statusText: Response.statusText
            });
          }
        })
        .then(jsonData => {
          this.todos = jsonData;
          this.loading = false;
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        });
    },

    changeItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.status =
        this.editedItem.status == "完成" ? "待办" : "完成";
      item.status = this.editedItem.status;

      fetch(baseUrl + "/api/remark/" + this.editedItem.id, {
        method: "put",
        headers: authHeader(),
        body: JSON.stringify(this.editedItem)
      }).then(function(Response) {
        console.log(Response);
      });
    },

    editItem(item) {
      this.editedIndex = this.todos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.informUid = this.editedItem.inform_uid.split(",");
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.todos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      fetch(baseUrl + "/api/remark/" + this.editedItem.id, {
        method: "delete",
        headers: authHeader()
      }).then(function(Response) {
        console.log(Response);
      });
      this.todos.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        fetch(baseUrl + "/api/remark/" + this.editedItem.id, {
          method: "put",
          headers: authHeader(),
          body: JSON.stringify(this.editedItem)
        }).then(function(Response) {
          console.log(Response);
        });

        Object.assign(this.todos[this.editedIndex], this.editedItem);
      } else {
        fetch(baseUrl + "/api/remark", {
          method: "post",
          headers: authHeader(),
          body: JSON.stringify(this.editedItem)
        }).then(function(Response) {
          console.log(Response);
        });

        this.todos.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
